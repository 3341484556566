/*
	import { getDefaultProviderByName } from '@/services/CheckbookReconciliation/BankDataProvider/GetDefaultByProviderName';
  import { notifyMessage, notifyError } from '@/services/notify'

  await getDefaultProviderByName ({
    params: {
      providerName,
      isDefault
    }
  })
  .then (({ result, successful, message }) => {
    if (!successful) {
      notifyError (message);
      throw new Error (message);
    }
    
    
  })
*/

import kms from '@/services/kms'
//
export async function getDefaultProviderByName({ params }) {
  let message = 'A problem occurred and the provider details could not be retrieved.'

  try {
    const provider = await kms.get(
      `/CheckbookReconciliation/BankDataProvider/GetDefaultByProviderName`,
      {
        params
      }
    )

    return {
      successful: true,
      message,
      provider
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    provider: []
  }
}
