<template>
  <PageContent
    tests-id="bankAccounts-page"
    :style="{
      height: `calc(100vh - 64px)`
    }"
    :containerStyles="{ height: '100%' }"
    id="bankAccounts-page"
  >
    <div>
      <ModernTable
        :region="region"
        :filters="filters"
        :rows="rows"
        :columns="columns"
        :pagination="{
          perPage: 12
        }"
        :loading="loading"
      >
        <template v-slot:header>
          <h3 style="display: inline-block">{{ $t('title') }}</h3>
          <div class="buttons">
            <b-button
              tests-id="add-linked"
              tag="router-link"
              to="/account/createlinked"
              type="is-primary"
              rounded
            >
              Add Linked
            </b-button>
            <b-button
              tests-id="add-un-linked"
              tag="router-link"
              to="/account/createunlinked"
              type="is-primary"
              rounded
            >
              Add Un-linked
            </b-button>
            <b-button
              tests-id="reconciliation-details"
              tag="router-link"
              to="bankAccountReconcileDetailList"
              type="is-primary"
              rounded
            >
              Reconciliation Details
            </b-button>
          </div>
          <div :style="{ position: 'absolute', top: 0, right: 0 }">
            <b-button
              type="is-ghost"
              @click="toggleFilters"
              :style="{
                float: 'right',
                marginTop: '4px'
              }"
              :class="'filter-button ' + (filters.show ? 'active' : '')"
            >
              <b-icon
                pack="mdi"
                icon="filter-outline"
                size="is-small"
                style="display: inline-block"
              ></b-icon>
              <div style="display: inline-block">Filter</div>
            </b-button>
          </div>
        </template>
      </ModernTable>
    </div>
  </PageContent>
</template>

<script>
import PageContent from '@/containers/PageContent'
import ModernTable from '@/components/tables/Modern/Table'
import { methods } from './keys/methods'
import { data } from './keys/data'
import { computed } from './keys/computed'

export default {
  components: { PageContent, ModernTable },

  data,
  computed,

  watch: {
    hoaId() {
      this.refresh()
    }
  },

  mounted() {
    this.refresh()
  },

  methods,

  i18n: {
    messages: {
      en: {
        // Or possibly "Financial Accounts"
        // (considering Credit Unions might not be designated as "banks")
        title: 'Bank Accounts'
      }
    }
  }
}
</script>
