//
//
//
import { notifyError } from '@/services/notify'
//
//
export async function upsert() {
  this.loading = true

  if (this.account.financialInstitutionID == null) {
    notifyError('Please link to your Plaid Bank Account.')
    return
  }

  if (this.isLinked) {
    await this.upsertLinked()
  } else {
    await this.upsertUnlinked()
  }

  await new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 500)
  })

  this.refresh({ skipPresets: true })
}
