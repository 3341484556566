<template>
  <div class="card" :style="{ paddingBottom: '20px' }">
    <div
      :style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '20px'
      }"
    >
      <h4 :style="{ margin: 0 }">Bank Account Listings</h4>

      <!-- <router-link
        v-if="!isLinked"
        class="button is-primary is-rounded"
        :to="{
          name: 'createBankAccountListing',
          params: {
            id: bankAccountID,
            finInstBankName: `${finInstBankName} - ${finAccountName}`
          }
        }"
      >Add Transaction</router-link> -->
    </div>

    <ModernTable
      :columns="columns"
      :rows="rows"
      :filters="{
        show: true
      }"
      :pagination="{
        perPage: 12
      }"
    />
  </div>
</template>

<script>
/*
  maybe list should be from differenent
  endpoints depending on whether is linked accout
  or unlinked account...?
  
  Linked: [get] /CheckbookReconciliation/BankAccountListing/List
    this is the list as retrieved from the bank
  
  Unlinked: [get] /CheckbookReconciliation/CheckbookEntry/List
    this is the list as recorded by portal user
*/

import { watch } from './parts/watch'
import { methods } from './parts/methods'
import { data } from './parts/data'
import { computed } from './parts/computed'

import ModernTable from '@/components/tables/Modern/Table'

export default {
  components: {
    ModernTable
  },

  /*
  props: {
    isLinked: {
      type: Boolean,
      default: true
    },
    //
    // true once the account has been loaded
    activityReady: {
      type: Boolean,
      default: false
    },
    bankAccountID: [Number, String],
    finInstBankName: String,
    finAccountName: String
  },
  */

  watch,
  mounted() {
    // this.refresh()
  },
  methods,
  data,
  computed
}
</script>
