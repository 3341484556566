/*
  example:
    import { getBankAccount } from '@/services/BankAccounts/BankAccount/Get';

    const { successful, message, bankAccount } = await getBankAccount ({
      bankAccountID
    });
*/

import kms from '@/services/kms'
//import { notifyProblem } from '@/services/notify'

export async function getBankAccount({ bankAccountID } = {}) {
  let message = `This bank account could not be found.  Possibly, the necessary credentials weren't provided, it doesn't exist, or it was deleted.`

  try {
    const result = await kms.get(`/BankAccounts/BankAccount/${bankAccountID}`)

    return {
      successful: true,
      message: '',
      result,
      //
      bankAccount: result
    }
  } catch (exception) {
    console.error(exception)
  }

  // notifyProblem (message);

  return {
    successful: false,
    message,
    result: null,
    //
    bankAccount: null
  }
}
