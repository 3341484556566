import { deleteUnlinkedBankAccount } from '@/services/BankAccounts/BankAccountUnlinked/Delete'

import { notifyError, notifyMessage } from '@/services/notify'

import _get from 'lodash/get'

export async function eraseUnlinkedAccount() {
  const bankAccountID = _get(this, ['account', 'bankAccountID'], '')
  const accountName = _get(this, ['account', 'name'], '')

  const { successful, message } = await deleteUnlinkedBankAccount({
    bankAccountID
  })
  if (!successful) {
    console.error(message)
    notifyError(message)
    return
  }

  notifyMessage(`${accountName} was successfully deleted.`)
  this.$router.push('/bankAccounts')
}
