/*
	import { getBankAccountListings } from '@/services/CheckbookReconciliation/BankAccountListing/List';

  await getBankAccountListings ({
    params: {
			hoaID,
      
      //
      // optional
      bankAccountID: 
      showUnreconciledOnly:
      targetDate: 
      startDate:
    }
  })
  .then (({ listings, successful, message }) => {
    if (!successful) {
      console.error (message);
      throw new Error (message);
    }
  })

*/

import kms from '@/services/kms'
import _get from 'lodash/get'

export async function getBankAccountListings({ params }) {
  let message = 'The transactions list could not be retrieved.'

  try {
    const result = await kms.get(`/CheckbookReconciliation/BankAccountListing/List`, {
      params
    })

    let list = _get(result, 'results', [])
    if (!Array.isArray(list)) {
      list = []
    }

    return {
      successful: true,
      message,
      listings: list
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    listings: []
  }
}
