export const PRESET_FORM_VALUES = Object.freeze({
  bankName: '',
  accountNumber: '',
  routingNumber: '',
  bankAccountTypeID: 0,
  bankDataProviderAccountID: '',
  bankGLAccountID: 0,
  interestIncomeGLAccountID: 0,
  financialInstitution: {}
})

export function data() {
  return {
    loading: true,
    found: null,
    errorMessage: null,
    isLinked: null,
    activityReady: false,
    account: {
      ...PRESET_FORM_VALUES
    },
    accountIsClosed: null,
    financialAccountID: '',
    interestIncomeAccounts: [],
    assetAccounts: [],
    confirmModal: false
  }
}
