/*
  example:
    import { addFinInstituion } from '@/services/CheckbookReconciliation/FinancialInstitution/Post';

    await addFinInstituion ({
      json: {
        hoaID: '',
        name: 'A Financial Institution',
        providerInstitutionID: null
      }
    }).then (({
      data,
      successful, 
      message
    }) => {
      if (!successful) {
        throw new Error (message)
        return;
      }
    })
    
*/

//
import kms from '@/services/kms'
import { notifyProblem } from '@/services/notify'
//
export async function addFinInstituion({ json } = {}) {
  const message = `A problem occurred and the financial institution could not be added.`
  const path = `/CheckbookReconciliation/FinancialInstitution`

  try {
    const result = await kms.post(path, json)

    return {
      successful: true,
      message: '',
      data: result
    }
  } catch (exception) {
    console.error(exception)
  }

  notifyProblem(message)

  return {
    successful: false,
    message,
    data: null
  }
}

///////
