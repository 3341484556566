//
import { PRESET_FORM_VALUES } from './../../data'
//
import { getBankAccount } from '@/services/BankAccounts/BankAccount/Get'
import { getGLAccountsList } from '@/services/GeneralLedger/Account/List'
import { getUnlinkedBankAccount } from '@/services/BankAccounts/BankAccountUnlinked/Get'
//
import { notifyError } from '@/services/notify'
//
import _get from 'lodash/get'
import _cloneDeep from 'lodash/cloneDeep'
//
export async function refresh({ skipPresets = false } = {}) {
  this.loading = true

  console.log('refreshing account details')

  let isLinked = null

  try {
    if (!skipPresets) {
      this.formData = { ...PRESET_FORM_VALUES }
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
    }

    await this.getProviderId()

    const bankAccountID = this.$route.params.id
    this.bankAccountID = bankAccountID

    const hoaID = this.hoaId

    const { successful, message, bankAccount } = await getBankAccount({
      bankAccountID
    })
    if (!successful) {
      this.found = false
      this.loading = false
      notifyError(message)
      return
    }

    this.found = true

    const account = _cloneDeep(bankAccount)

    isLinked = _get(account, ['isLinked'], null)
    this.isLinked = isLinked

    if (isLinked) {
      this.account = account

      this.accountIsClosed = this.account.isClosed
    } else {
      await this.loadBankAccountTypesList()

      const { unlinkedBankAccount, successful, message } = await getUnlinkedBankAccount({
        bankAccountID
      })
      if (!successful) {
        this.found = false
        this.loading = false
        notifyError(message)
        return
      }

      this.account = unlinkedBankAccount
      this.currentFinancialInstitutionName = _get(
        unlinkedBankAccount,
        ['financialInstitution', 'name'],
        null
      )
    }

    console.log('???')

    await getGLAccountsList({
      parseTypes: true,
      params: {
        hoaID
      }
    }).then(({ successful, message, parsedAccounts }) => {
      if (!successful) {
        notifyError(message)
        throw new Error(message)
      }

      console.log({ parsedAccounts })

      this.interestIncomeAccounts = parsedAccounts['Income']
      this.assetAccounts = parsedAccounts['Asset']
    })

    console.log('?????')
  } catch (e) {
    notifyError(e)
  }

  console.log('account refreshed?')

  this.activityReady = true
  this.loading = false

  // this.$forceUpdate();

  const account = this.account

  console.log('activity', this.$refs.activity)

  this.$refs.activity.update({
    isLinked,
    bankAccountID: _get(account, 'bankAccountID', ''),
    finInstBankName: _get(account, ['financialInstitution', 'name'], ''),
    finAccountName: _get(account, 'name', '')
  })
}
