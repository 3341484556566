import { notifyProblem } from '@/services/notify'

import moment from 'moment'
import _get from 'lodash/get'

export function getLinkedUpsertDetails() {
  const account = _get(this, 'account', null)
  const today = moment().toDate()
  //
  //
  //
  //
  const hoaID = this.hoaId
  if (!Number.isInteger(hoaID)) {
    notifyProblem(
      `A problem occurred with the update, the HOA id found was not the correct format.  Received Id: ${hoaID}`
    )
    return false
  }
  //
  const isLinked = true
  //
  const name = _get(account, 'name', '')
  //
  //
  //  The id of the data provider, for example:
  //    plaid = either Integer or Integer string
  const bankDataProviderID = _get(account, 'bankDataProviderID', '')
  //
  //  The id of the commerce account, from the provider,
  //    if plaid is provider:
  //      requirements: alpha numeric string?
  const bankDataProviderAccountID = _get(account, 'bankDataProviderAccountID', '')
  //
  //  internal id of the bank account
  //    requirements: integer
  const bankAccountID = _get(account, 'bankAccountID', '')
  if (!Number.isInteger(bankAccountID)) {
    notifyProblem(
      `A problem occurred with the update, the bank account id found was not the correct format.  Received Id: ${bankAccountID}`
    )
    return false
  }
  //
  //  The label of the account, as received via plaid
  //    requirements: string?
  const plaidAccountType = _get(account, 'plaidAccountType', '')
  //
  //  essentially this is the last 4 digits of the account number:
  //    for example: "************1234"
  //
  //    requirements: 16 digit string??
  const plaidAccountMask = _get(account, 'plaidAccountMask', '')
  //
  //
  const plaidInstitutionId = _get(account, 'plaidInstitutionId', null)
  //
  //  internal id of the financial instituion
  //    requirements: integer
  const financialInstitutionID = _get(
    account,
    ['financialInstitution', 'financialInstitutionID'],
    null
  )
  if (!Number.isInteger(financialInstitutionID)) {
    notifyProblem(
      `A problem occurred with the update. The financial institution id found was not the correct format.  Received Id: ${financialInstitutionID}`
    )
    return false
  }
  //
  //  the linked "Asset" Account
  //    requirements: integer
  const bankGLAccountID = _get(account, 'bankGLAccountID', '')
  if (!Number.isInteger(bankGLAccountID)) {
    notifyProblem(
      `A problem occurred with the update. The Asset Account id found was not the correct format.  Received Id: ${bankGLAccountID}`
    )
    return false
  }
  //
  //  the linked "Interest Income" Account
  //    requirements: integer
  const interestIncomeGLAccountID = _get(account, 'interestIncomeGLAccountID', '')
  if (!Number.isInteger(interestIncomeGLAccountID)) {
    notifyProblem(
      `A problem occurred with the update. The Interest Income Account id found was not the correct format.  Received Id: ${bankGLAccountID}`
    )
    return false
  }
  //
  //  whether or not the account is closed
  //    requirements: boolean
  const isClosed = _get(account, 'isClosed', '')
  if (typeof isClosed !== 'boolean') {
    notifyProblem(
      `A problem occurred with the update. The account is value found was not the correct format.  Received: ${isClosed}`
    )
    return false
  }

  let closedDate = null
  if (isClosed) {
    closedDate = today
  }

  const modifiedDate = today

  return {
    hoaID,
    //
    isLinked,
    //
    name,
    //
    bankDataProviderID,
    //
    bankDataProviderAccountID,
    bankAccountID,
    plaidAccountMask,
    plaidAccountType,
    //
    plaidInstitutionId,
    financialInstitutionID,
    //
    bankGLAccountID,
    interestIncomeGLAccountID,
    //
    closedDate,
    //
    modifiedDate
  }
}
