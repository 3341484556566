<template>
  <div id="menucontrol">
    <b-dropdown aria-role="menu" position="is-bottom-left" :close-on-click="false">
      <template #trigger>
        <b-button
          class="ellipsisbutton font-bold button is-info is-light is-small is-outline is-rounded"
          icon-right="menu-down"
          >More Actions</b-button
        >
      </template>
      <div>
        <b-dropdown-item
          aria-role="menuitem"
          class="ellipsis font-bold button is-info is-light is-small is-outline"
          has-link
          @click="onAccountDetailsClick()"
        >
          Account Details
        </b-dropdown-item>
      </div>
      <div>
        <b-dropdown-item
          aria-role="menuitem"
          class="ellipsis font-bold button is-info is-light is-small is-outline"
          has-link
          @click="onReconcileClick()"
          >Reconcile
        </b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    onAccountDetailsClick: Function,
    onReconcileClick: Function
  }
}
</script>
<style lang="scss" scoped>
#menucontrol {
  .ellipsis {
    cursor: pointer;
    width: 60%;
    text-align: left;
    border: 1px solid #022954;
    float: right;
  }

  .ellipsisbutton {
    width: 120px;
  }
}
</style>
