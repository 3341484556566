<template>
  <div>
    <router-link
      class="button is-info is-light is-small is-outline is-rounded"
      :to="{
        name: 'bankaccountlisting',
        params: {
          id,
          finInstBankName
        }
      }"
      >Details</router-link
    >
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String],
      default: ''
    },
    finInstBankName: {
      type: String,
      default: ''
    }
  }
}
</script>
