import { formatDate } from '@/filters'
import { getBankAccountListings } from '@/services/CheckbookReconciliation/BankAccountListing/List'
import _get from 'lodash/get'
import { notifyProblem } from '@/services/notify'

export const methods = {
  async update({ isLinked, bankAccountID, finInstBankName, finAccountName }) {
    /*
      :isLinked="isLinked"
      :bankAccountID="_get(account, 'bankAccountID', '')"
      :finInstBankName="_get(account, ['financialInstitution', 'name'], '')"
      :finAccountName="_get(account, 'name', '')"
    */

    console.log('update')

    this.isLinked = isLinked
    this.bankAccountID = bankAccountID
    this.finInstBankName = finInstBankName
    this.finAccountName = finAccountName

    this.refresh()
  },
  async refresh() {
    console.log('refresh', this.activityReady)

    const hoaID = this.hoaId

    const bankAccountID = parseInt(this.bankAccountID)
    if (!Number.isInteger(bankAccountID) || bankAccountID <= 0) {
      notifyProblem(`A problem occurred and the bank account id could not be parsed.`)
      return
    }

    await getBankAccountListings({
      params: {
        hoaID,

        bankAccountID,
        showUnreconciledOnly: false
      }
    }).then(({ listings, successful, message }) => {
      if (!successful) {
        console.error(message)
        throw new Error(message)
      }

      this.rows = this.parseListings({ listings })
    })

    console.log('rows', this.rows)
  },
  parseListings({ listings }) {
    // const financialInstitution = this.finInstBankName
    // const accountName = this.finAccountName

    // const isLinked = this.isLinked

    return listings.map(tx => {
      return {
        date: formatDate(tx.transactionDate),

        payee: _get(tx, ['bankAccount', 'name'], ''),
        description: _get(tx, ['description'], ''),
        amount: Number.parseFloat(_get(tx, ['amount'], 0)).toFixed(2),

        checkNumber: _get(tx, ['checkNumber'], ''),

        clearedDate: formatDate(_get(tx, ['clearedDate'], ''))

        /*
          this is a button for editing an particular
          bank account listing
        
        ...(!isLinked
          ? {
              details: {
                component: TxDetails,
                props: {
                  id: _get(tx, ['bankAccountListingID'], ''),
                  finInstBankName: `${financialInstitution} - ${accountName}`
                }
              }
            }
          : {})
        */
      }
    })
  }
}
