/*
  example:
    import { listUnlinkedBankAccounts } from '@/services/BankAccountUnlinked/BankAccount/List';
    import { notifyProblem } from '@/services/notify'

    await listBankAccounts ({
      params: {
        hoaID: 
      }
    })
    .then (({ successful, message, unlinkedBankAccounts }) => {
      if (!successful) {
        notifyProblem (message);
        return;
      }
    })
    
*/

import kms from '@/services/kms'

export async function listUnlinkedBankAccounts({ params = {} } = {}) {
  try {
    const result = await kms.get(`/BankAccounts/BankAccountUnlinked/List`, { params })

    return {
      successful: true,
      message: '',
      result,
      //
      unlinkedBankAccounts: result.results
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message: `A problem occurred and the bank accounts list could not be retrieved.`,
    result: null,
    //
    bankAccounts: []
  }
}
