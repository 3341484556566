/*
	import { deleteUnlinkedBankAccount } from '@/services/BankAccounts/BankAccountUnlinked/Delete';

  const { result, successful, message } = await deleteUnlinkedBankAccount ({
    bankAccountID
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function deleteUnlinkedBankAccount({ bankAccountID }) {
  let message = 'The unlinked bank account could not be deleted.'

  try {
    const result = await kms.delete(
      `/BankAccounts/BankAccountUnlinked/${bankAccountID}?forceDelete=false`
    )

    return {
      successful: true,
      message: '',
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: null
  }
}
