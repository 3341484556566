var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContent',{attrs:{"title":_vm.$t('bankAccount.detailsTitle')}},[(_vm.found)?_c('div',[(_vm.account && _vm.account != undefined)?_c('div',{staticClass:"box is-8"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""}},[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.upsert)}}},[_c('fieldset',[_c('div',{staticClass:"form-grid"},[(_vm.isLinked)?_c('div',{staticClass:"column is-8 has-text-left"},[_c('b-field',{attrs:{"label":"Account Name"}},[_c('h4',{style:({
                        margin: 0,
                        padding: 0
                      })},[_vm._v(" "+_vm._s(_vm.account.name)+" ")])])],1):_vm._e(),(!_vm.isLinked)?_c('div',{staticClass:"column is-8"},[_c('valid-input',{attrs:{"vid":"name","name":"Account Name","label":"Account Name","placeholder":"Account Number","type":"text","disabled":true,"readonly":true,"rules":"required"},model:{value:(_vm.account.name),callback:function ($$v) {_vm.$set(_vm.account, "name", $$v)},expression:"account.name"}})],1):_vm._e(),(
                    !_vm.isLinked &&
                      _vm.bankAccountTypes &&
                      _vm.bankAccountTypes != undefined &&
                      _vm.account &&
                      _vm.account != undefined
                  )?_c('div',{staticClass:"column is-8"},[_c('valid-select',{attrs:{"placeholder":"Account Type","label":"Account Type","vid":"accountType","rules":"required","disabled":true,"readonly":true},model:{value:(_vm.account.bankAccountTypeID),callback:function ($$v) {_vm.$set(_vm.account, "bankAccountTypeID", $$v)},expression:"account.bankAccountTypeID"}},_vm._l((_vm.bankAccountTypes),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(_vm._s(option.label))])}),0)],1):_vm._e(),(!_vm.isLinked)?_c('div',{staticClass:"column is-8"},[_c('valid-input',{attrs:{"vid":"financialInstitutionName","name":"Financial Institution Name","label":"Financial Institution Name","placeholder":"Financial Institution Name","type":"text","disabled":true,"readonly":true,"rules":"required"},model:{value:(_vm.account.financialInstitution.name),callback:function ($$v) {_vm.$set(_vm.account.financialInstitution, "name", $$v)},expression:"account.financialInstitution.name"}})],1):_vm._e(),_c('div',{staticClass:"column is-8",style:(Object.assign({}, {display: 'flex'},
                    (_vm.breakpoint === 'desktop'
                      ? { flexDirection: 'row' }
                      : { flexDirection: 'column' })))},[(_vm.isLinked)?_c('b-field',{style:({ marginRight: '8px' }),attrs:{"label":"Financial Instituion","disabled":true,"readonly":true}},[_c('b-tag',{style:({
                        margin: 0
                      }),attrs:{"size":"is-large"}},[_vm._v(_vm._s(_vm.account.financialInstitution.name))])],1):_vm._e(),(_vm.isLinked)?_c('b-field',{style:({ marginRight: '8px' }),attrs:{"label":"Linked Account"}},[_c('b-tag',{attrs:{"size":"is-large"}},[_vm._v(_vm._s(_vm.account.plaidAccountType))])],1):_vm._e(),(_vm.isLinked)?_c('b-field',{attrs:{"label":"Account Number"}},[_c('b-tag',{attrs:{"size":"is-large"}},[_vm._v("**** **** **** "+_vm._s(_vm.account.plaidAccountMask))])],1):_vm._e()],1),_c('div',{staticClass:"column is-8"},[_c('valid-select',{attrs:{"placeholder":"Asset Account","label":"Asset Account","vid":"assetAccount","rules":"required","disabled":true,"readonly":true},model:{value:(_vm.account.bankGLAccountID),callback:function ($$v) {_vm.$set(_vm.account, "bankGLAccountID", $$v)},expression:"account.bankGLAccountID"}},_vm._l((_vm.assetAccounts),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(_vm._s(option.label))])}),0)],1),(
                    _vm.interestIncomeAccounts &&
                      _vm.interestIncomeAccounts != undefined &&
                      _vm.account &&
                      _vm.account != undefined
                  )?_c('div',{staticClass:"column is-8"},[_c('valid-select',{attrs:{"placeholder":"Interest Income","label":"Interest Income","vid":"interestIncome","rules":"required","disabled":true,"readonly":true},model:{value:(_vm.account.interestIncomeGLAccountID),callback:function ($$v) {_vm.$set(_vm.account, "interestIncomeGLAccountID", $$v)},expression:"account.interestIncomeGLAccountID"}},_vm._l((_vm.interestIncomeAccounts),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(_vm._s(option.label))])}),0)],1):_vm._e(),(!_vm.isLinked && _vm.account && _vm.account != undefined)?_c('div',{staticClass:"column is-8"},[_c('valid-input',{attrs:{"vid":"accountNumber","name":"Account Number","label":"Account Number","maxlength":"34","type":"text","placeholder":"Account Number","disabled":true,"readonly":true,"rules":"required|max:34|numeric"},model:{value:(_vm.account.accountNumber),callback:function ($$v) {_vm.$set(_vm.account, "accountNumber", $$v)},expression:"account.accountNumber"}})],1):_vm._e(),(!_vm.isLinked && _vm.account && _vm.account != undefined)?_c('div',{staticClass:"column is-8"},[_c('valid-input',{attrs:{"vid":"routingNumber","name":"Routing Number","label":"Routing Number","maxlength":"9","type":"text","placeholder":"Routing Number","disabled":true,"readonly":true,"rules":"required|max:9|length:9|numeric"},model:{value:(_vm.account.routingNumber),callback:function ($$v) {_vm.$set(_vm.account, "routingNumber", $$v)},expression:"account.routingNumber"}})],1):_vm._e(),_c('div',{staticClass:"column is-8 card"},[_c('b-field',{attrs:{"label":"Account is Closed"}},[_vm._v(" "+_vm._s(("" + (_vm.account.isClosed === true ? 'Yes' : 'No')))+" ")])],1)])]),_c('fieldset',[_c('ValidationProvider',{attrs:{"vid":"providerErrors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('div',{staticClass:"field"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)])])]}}],null,false,1049483527)})],1):_vm._e(),_c('Activity',{ref:"activity",attrs:{"ready":_vm.activityReady,"isLinked":_vm.isLinked,"bankAccountID":_vm._get(_vm.account, 'bankAccountID', ''),"finInstBankName":_vm._get(_vm.account, ['financialInstitution', 'name'], ''),"finAccountName":_vm._get(_vm.account, 'name', '')}}),_c('UpdateModal',{ref:"updateModal"})],1):_vm._e(),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }