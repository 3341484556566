//
//
//
//
//
//\___
import { getDefaultProviderByName } from '@/services/CheckbookReconciliation/BankDataProvider/GetDefaultByProviderName'
//
import { notifyError } from '@/services/notify'
//
import { parseInteger } from '@/utilities/parse/Integer'
//
//
export async function getProviderId() {
  await getDefaultProviderByName({
    params: {
      providerName: 'Plaid',
      isDefault: true
    }
  }).then(({ provider, successful, message }) => {
    if (!successful) {
      notifyError(message)
      throw new Error(message)
    }

    this.bankDataProviderID = parseInteger(provider.bankDataProviderID)
  })
}
