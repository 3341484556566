//
import { notifyProblem } from '@/services/notify'
import { addFinInstituion } from '@/services/CheckbookReconciliation/FinancialInstitution/Post'
//
import moment from 'moment'
import _get from 'lodash/get'
import { parseInteger } from '@/utilities/parse/Integer'
//
export async function getUnlinkedUpsertDetails() {
  const account = _get(this, 'account', null)
  const today = moment().toDate()
  //
  //
  //
  //
  const hoaID = this.hoaId
  if (!Number.isInteger(hoaID)) {
    notifyProblem(
      `A problem occurred with the update, the HOA id found was not the correct format.  Received Id: ${hoaID}`
    )
    return false
  }
  //
  const isLinked = false
  //
  const name = _get(account, 'name', '')
  //
  //  internal id of the bank account
  //    requirements: integer
  const bankAccountID = parseInteger(_get(account, 'bankAccountID', ''))
  if (!Number.isInteger(bankAccountID)) {
    notifyProblem(
      `A problem occurred with the update, the bank account id found was not the correct format.  Received Id: ${bankAccountID}`
    )
    return false
  }
  //
  const bankAccountTypeID = parseInteger(_get(account, 'bankAccountTypeID', ''))
  if (!Number.isInteger(bankAccountTypeID)) {
    notifyProblem(
      `A problem occurred with the update, the bank account type id found was not the correct format.  Received Id: ${bankAccountTypeID}`
    )
    return false
  }
  //
  let accountNumber = parseInteger(_get(account, 'accountNumber', ''))
  if (!Number.isInteger(accountNumber)) {
    notifyProblem(
      `A problem occurred with the update, the account number found was not the correct format.  Received: ${accountNumber}`
    )
    return false
  }
  accountNumber = accountNumber.toString()
  //
  let routingNumber = parseInteger(_get(account, 'routingNumber', ''))
  if (!Number.isInteger(routingNumber)) {
    notifyProblem(
      `A problem occurred with the update, the routing number found was not the correct format.  Received: ${routingNumber}`
    )
    return false
  }
  routingNumber = routingNumber.toString()

  //
  //  the linked "Asset" Account
  //    requirements: integer
  const bankGLAccountID = _get(account, 'bankGLAccountID', '')
  if (!Number.isInteger(bankGLAccountID)) {
    notifyProblem(
      `A problem occurred with the update. The Asset Account id found was not the correct format.  Received Id: ${bankGLAccountID}`
    )
    return false
  }
  //
  //  the linked "Interest Income" Account
  //    requirements: integer
  const interestIncomeGLAccountID = _get(account, 'interestIncomeGLAccountID', '')
  if (!Number.isInteger(interestIncomeGLAccountID)) {
    notifyProblem(
      `A problem occurred with the update. The Interest Income Account id found was not the correct format.  Received Id: ${bankGLAccountID}`
    )
    return false
  }
  //
  //  whether or not the account is closed
  //    requirements: boolean
  const isClosed = _get(account, 'isClosed', '')
  if (typeof isClosed !== 'boolean') {
    notifyProblem(
      `A problem occurred with the update. The account is value found was not the correct format.  Received: ${isClosed}`
    )
    return false
  }

  let closedDate = null
  if (isClosed) {
    closedDate = today
  }

  const modifiedDate = today

  const newFinInstName = _get(account, ['financialInstitution', 'name'], '')
  const currentFinancialInstitutionName = this.currentFinancialInstitutionName

  let financialInstitutionID = null
  if (newFinInstName !== currentFinancialInstitutionName) {
    await addFinInstituion({
      json: {
        hoaID,
        name: newFinInstName,
        providerInstitutionID: null,
        setAsUnlinked: true
      }
    }).then(({ successful, message, data }) => {
      if (!successful) {
        console.error(message)
      } else {
        financialInstitutionID = data.financialInstitutionID
      }
    })

    if (financialInstitutionID === null) {
      return false
    }
  }

  if (financialInstitutionID === null) {
    financialInstitutionID = this.account.financialInstitutionID
  }

  return {
    hoaID,
    //
    isLinked,
    //
    name,
    //
    bankAccountID,
    bankAccountTypeID,
    accountNumber,
    routingNumber,
    //
    bankGLAccountID,
    interestIncomeGLAccountID,
    //
    financialInstitutionID,
    //
    closedDate,
    //
    modifiedDate
  }
}
