import { upsert } from './list/upsert'
import { refresh } from './list/refresh'
import { getProviderId } from './list/getProviderId'
import { eraseAccount } from './list/eraseAccount'
import { getLinkedUpsertDetails } from './list/getLinkedUpsertDetails'
import { getUnlinkedUpsertDetails } from './list/getUnlinkedUpsertDetails'
import { upsertLinked } from './list/upsertLinked'
import { upsertUnlinked } from './list/upsertUnlinked'
import { eraseLinkedAccount } from './list/eraseLinkedAccount'
import { eraseUnlinkedAccount } from './list/eraseUnlinkedAccount'
import _get from 'lodash/get'
import { mapActions } from 'vuex'

export const methods = {
  _get,
  upsert,
  refresh,
  getProviderId,
  getUnlinkedUpsertDetails,
  getLinkedUpsertDetails,
  upsertLinked,
  upsertUnlinked,
  eraseAccount,
  eraseLinkedAccount,
  eraseUnlinkedAccount,

  ...mapActions('bankaccount', ['loadBankAccountTypesList']),
  ...mapActions('hoa', ['loadAssetGLAccountsList', 'loadInterestIncomeGLAccountsList']),

  openUpdateModal() {
    this.$refs.updateModal.open()
  },
  onConfirm() {
    this.$emit('confirm')
  },
  onCancel() {
    this.$emit('cancel')
  }
}
