//
//
import { mapState } from 'vuex'
import _get from 'lodash/get'
//
export const computed = {
  ...mapState({
    hoaID: state => _get(state, ['user', 'selectedHoaId'], null)
  })
}
