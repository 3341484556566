export const watch = {
  hoaId(hoaId) {
    console.log('hoaId', hoaId)
    //this.refresh()
  },
  bankAccountID(bankAccountID) {
    console.log('watch', bankAccountID)
    // this.refresh()
  },
  activityReady(activityReady) {
    console.log('activityReady', activityReady)

    // this.refresh ()
  }
}
