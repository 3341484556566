//
import _get from 'lodash/get'
//
//
export async function eraseAccount() {
  const isLinked = this.isLinked

  const accountName = _get(this, ['account', 'name'], '')

  await new Promise(resolve => {
    this.$buefy.dialog.confirm({
      title: 'Deleting account',
      message: `Are you sure you would like to <b>delete</b> this account, '${accountName}'?`,
      confirmText: 'Delete Account',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        resolve()
      }
    })
  })

  if (isLinked) {
    await this.eraseLinkedAccount()
  } else {
    await this.eraseUnlinkedAccount()
  }
}
