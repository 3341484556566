<template>
  <div update-modal>
    <Modal
      :toggle.sync="isOpen"
      :styles="{
        modalContent: { height: '500px' },
        modal: { maxHeight: '90vh' }
      }"
    >
      <template v-slot:header>Bank Account Updater</template>

      <template v-slot:footer>
        <div :style="{ float: 'right' }">
          <b-tooltip :active="prevToolTip.length >= 1" :label="prevToolTip" position="is-right">
            <b-button
              prev-button
              icon-left="chevron-left"
              size="is-medium"
              :style="{ marginRight: '8px' }"
              @click="prevStep"
              :disabled="prevPrevented"
            ></b-button>
          </b-tooltip>

          <b-tooltip :active="nextToolTip.length >= 1" :label="nextToolTip" position="is-left">
            <b-button
              next-button
              icon-left="chevron-right"
              size="is-medium"
              @click="nextStep"
              :disabled="nextPrevented"
            ></b-button>
          </b-tooltip>
        </div>
      </template>

      <div>
        <transition name="fade" mode="out-in" appear>
          <b-steps v-model="step" :has-navigation="false" label-position="bottom" size="is-small">
            <b-step-item label="Link Account">
              <plaid-link />
            </b-step-item>
          </b-steps>
        </transition>
      </div>

      <b-loading v-model="loading"></b-loading>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import PlaidLink from '@/components/Plaid/Link'

import { methods } from './keys/methods'

export default {
  components: {
    Modal,
    PlaidLink
  },
  data() {
    return {
      isOpen: false,

      step: 0,
      nextPrevented: false,
      nextToolTip: '',
      prevPrevented: false,
      prevToolTip: '',

      loading: false
    }
  },
  methods
}
</script>
