/*
  example:
    import { upsertBankAccountForClient } from '@/services/BankAccounts/BankAccount/UpsertBankAccountForClient';
*/

/*
  Prerequisites:
    bankGLAccountID:
      /management/managechartofaccounts
    
    interestIncomeGLAccountID:
      /management/managechartofaccounts
*/

/*
    ///////////////////////////////////////////
    // Upserting a linked account
    ///////////////////////////////////////////

    const { successful, message } = await upsertBankAccountForClient ({
      authorization,
      json: {
        "hoaID": 993,  
        //
        "bankDataProviderID": 1,
        //
        "name": "KeyBank - Student Loan",
        
        "plaidAccountMask": "7777",
        "plaidAccountType": "Plaid Student Loan",
        //
        //  plaid data:
        //    institution -> plaidInstitutionId):     e.g. the id of the: bank, credit union, etc.
        //    account -> bankDataProviderAccountID):  e.g. checking, savings, mortgage, etc.
        //
        //  internal data:
        //    institution -> financialInstitutionID): e.g. the id of the: bank, credit union, etc.
        //
        "plaidInstitutionId": "ins_29",
        "bankDataProviderAccountID": "5EX6WrdLAvuLRqv78b59ijvXLz3lEGFpzVJBR",
        "financialInstitutionID": 46,
        //
        "bankGLAccountID": 2565,
        "interestIncomeGLAccountID": 2564
      }
    }).then (({ successful, message, result }) => {
      if (!successful) {
        notifyError (message)
        throw new Error (message)
      }
      
              
    })
*/

import kms from '@/services/kms'

export async function upsertBankAccountForClient({
  json,

  // "updated" or "added"
  purpose = 'upserted'
} = {}) {
  let message = `The account could not be ${purpose}.`

  let exception = null

  try {
    const result = await kms.post(`/BankAccounts/BankAccount/UpsertBankAccountForClient`, json)

    return {
      successful: true,
      message: `The account was ${purpose} successfully.`,
      result,
      exception: null
    }
  } catch (_exception) {
    console.error(_exception)

    exception = _exception
  }

  return {
    successful: false,
    message,
    result: null,
    exception
  }
}

///////
