var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",style:({ paddingBottom: '20px' })},[_c('div',{style:({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '20px'
    })},[_c('h4',{style:({ margin: 0 })},[_vm._v("Bank Account Listings")])]),_c('ModernTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"filters":{
      show: true
    },"pagination":{
      perPage: 12
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }