var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContent',{style:({
    height: "calc(100vh - 64px)"
  }),attrs:{"tests-id":"bankAccounts-page","containerStyles":{ height: '100%' },"id":"bankAccounts-page"}},[_c('div',[_c('ModernTable',{attrs:{"region":_vm.region,"filters":_vm.filters,"rows":_vm.rows,"columns":_vm.columns,"pagination":{
        perPage: 12
      },"loading":_vm.loading},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',{staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(_vm.$t('title')))]),_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"tests-id":"add-linked","tag":"router-link","to":"/account/createlinked","type":"is-primary","rounded":""}},[_vm._v(" Add Linked ")]),_c('b-button',{attrs:{"tests-id":"add-un-linked","tag":"router-link","to":"/account/createunlinked","type":"is-primary","rounded":""}},[_vm._v(" Add Un-linked ")]),_c('b-button',{attrs:{"tests-id":"reconciliation-details","tag":"router-link","to":"bankAccountReconcileDetailList","type":"is-primary","rounded":""}},[_vm._v(" Reconciliation Details ")])],1),_c('div',{style:({ position: 'absolute', top: 0, right: 0 })},[_c('b-button',{class:'filter-button ' + (_vm.filters.show ? 'active' : ''),style:({
              float: 'right',
              marginTop: '4px'
            }),attrs:{"type":"is-ghost"},on:{"click":_vm.toggleFilters}},[_c('b-icon',{staticStyle:{"display":"inline-block"},attrs:{"pack":"mdi","icon":"filter-outline","size":"is-small"}}),_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("Filter")])],1)],1)]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }