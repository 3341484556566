/*


*/

import { listBankAccounts } from '@/services/BankAccounts/BankAccount/List'
import { listUnlinkedBankAccounts } from '@/services/BankAccounts/BankAccountUnlinked/List'

import { notifyProblem } from '@/services/notify'

import _get from 'lodash/get'
import { parseDate } from '@/utilities/Date/parse'
import Menu from './../components/Menu'

export const methods = {
  async refresh() {
    this.loading = true

    let list = []

    await listBankAccounts({
      params: {
        hoaID: this.hoaID
      }
    }).then(({ successful, message, bankAccounts }) => {
      if (!successful) {
        notifyProblem(message)
        return
      }

      list = list.concat(bankAccounts)
    })

    await listUnlinkedBankAccounts({
      params: {
        hoaID: this.hoaID
      }
    }).then(({ successful, message, unlinkedBankAccounts }) => {
      if (!successful) {
        notifyProblem(message)
        return
      }

      list = list.concat(unlinkedBankAccounts)
    })

    this.rows = this.parseAccounts({ accounts: list })

    this.loading = false
  },

  toggleFilters: function() {
    this.filters.show = !this.filters.show
  },

  parseAccounts({ accounts }) {
    if (!Array.isArray(accounts)) {
      return []
    }

    const dateFormat = 'LL'

    return accounts.map(account => {
      const isLinked = _get(account, 'isLinked', null)

      let accountType
      if (isLinked) {
        accountType = _get(account, 'plaidAccountType', null)
      } else {
        accountType = _get(account, 'accountType', null)
      }

      let processIsLinked = 'no'
      if (isLinked && isLinked === true) {
        processIsLinked = 'yes'
      }

      let isClosed = _get(account, 'isClosed', null)
      if (isClosed && isClosed === true) {
        isClosed = 'yes'
      } else {
        isClosed = 'no'
      }

      const bankAccountID = _get(account, 'bankAccountID', 0)
      const accountName = _get(account, 'name', '')
      const financialInstitutionName = _get(account, 'bankAcfinancialInstitution.namecountID', '')
      const defaultTargetDate = _get(account, 'defaultTargetDate', '')

      return {
        name: _get(account, 'name', null),

        institution: _get(account, 'financialInstitution.name', null),
        balance: Number.parseFloat(_get(account, 'summedBankAccountListings', 0)).toFixed(2),
        accountType,
        isClosed,
        processIsLinked,
        createdDate: {
          date: parseDate(_get(account, 'createdDate', null), dateFormat),
          format: dateFormat
        },
        menu: {
          component: Menu,
          props: {
            onAccountDetailsClick: () => {
              this.redirectToAccountDetails(bankAccountID)
            },
            onReconcileClick: () => {
              this.redirectToReconcile(
                bankAccountID,
                accountName,
                financialInstitutionName,
                defaultTargetDate
              )
            }
          }
        }
      }
    })
  },

  redirectToAccountDetails(id) {
    this.$router.push({
      name: 'bankAccount',
      params: {
        id: id
      }
    })
  },

  redirectToReconcile(id, name, financialInstitution, defaultTargetDate) {
    this.$router.push({
      name: 'bankAccountReconcile',
      params: {
        id: id,
        finInstBankName: `${name} - ${financialInstitution}`,
        defaultTargetDate: defaultTargetDate
      }
    })
  }
}
