<template>
  <PageContent :title="$t('bankAccount.detailsTitle')">
    <div v-if="found">
      <div class="box is-8" v-if="account && account != undefined">
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(upsert)" class="form">
              <fieldset>
                <div class="form-grid">
                  <div class="column is-8 has-text-left" v-if="isLinked">
                    <b-field label="Account Name">
                      <h4
                        :style="{
                          margin: 0,
                          padding: 0
                        }"
                      >
                        {{ account.name }}
                      </h4>
                    </b-field>
                  </div>

                  <div class="column is-8" v-if="!isLinked">
                    <valid-input
                      v-model="account.name"
                      vid="name"
                      name="Account Name"
                      label="Account Name"
                      placeholder="Account Number"
                      type="text"
                      :disabled="true"
                      :readonly="true"
                      rules="required"
                    />
                  </div>

                  <div
                    class="column is-8"
                    v-if="
                      !isLinked &&
                        bankAccountTypes &&
                        bankAccountTypes != undefined &&
                        account &&
                        account != undefined
                    "
                  >
                    <valid-select
                      placeholder="Account Type"
                      label="Account Type"
                      vid="accountType"
                      rules="required"
                      :disabled="true"
                      :readonly="true"
                      v-model="account.bankAccountTypeID"
                    >
                      <option
                        v-for="option in bankAccountTypes"
                        :value="option.value"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </valid-select>
                  </div>

                  <div v-if="!isLinked" class="column is-8">
                    <valid-input
                      v-model="account.financialInstitution.name"
                      vid="financialInstitutionName"
                      name="Financial Institution Name"
                      label="Financial Institution Name"
                      placeholder="Financial Institution Name"
                      type="text"
                      :disabled="true"
                      :readonly="true"
                      rules="required"
                    />
                  </div>

                  <div
                    class="column is-8"
                    :style="{
                      display: 'flex',
                      ...(breakpoint === 'desktop'
                        ? { flexDirection: 'row' }
                        : { flexDirection: 'column' })
                    }"
                  >
                    <b-field
                      v-if="isLinked"
                      label="Financial Instituion"
                      :style="{ marginRight: '8px' }"
                      :disabled="true"
                      :readonly="true"
                    >
                      <b-tag
                        size="is-large"
                        :style="{
                          margin: 0
                        }"
                        >{{ account.financialInstitution.name }}</b-tag
                      >
                    </b-field>

                    <b-field label="Linked Account" v-if="isLinked" :style="{ marginRight: '8px' }">
                      <b-tag size="is-large">{{ account.plaidAccountType }}</b-tag>
                    </b-field>

                    <b-field label="Account Number" v-if="isLinked">
                      <b-tag size="is-large">**** **** **** {{ account.plaidAccountMask }}</b-tag>
                    </b-field>
                  </div>

                  <div class="column is-8">
                    <valid-select
                      placeholder="Asset Account"
                      label="Asset Account"
                      vid="assetAccount"
                      rules="required"
                      :disabled="true"
                      :readonly="true"
                      v-model="account.bankGLAccountID"
                    >
                      <option
                        v-for="option in assetAccounts"
                        :value="option.value"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </valid-select>
                  </div>
                  <div
                    class="column is-8"
                    v-if="
                      interestIncomeAccounts &&
                        interestIncomeAccounts != undefined &&
                        account &&
                        account != undefined
                    "
                  >
                    <valid-select
                      placeholder="Interest Income"
                      label="Interest Income"
                      vid="interestIncome"
                      rules="required"
                      :disabled="true"
                      :readonly="true"
                      v-model="account.interestIncomeGLAccountID"
                    >
                      <option
                        v-for="option in interestIncomeAccounts"
                        :value="option.value"
                        :key="option.value"
                        >{{ option.label }}</option
                      >
                    </valid-select>
                  </div>
                  <div class="column is-8" v-if="!isLinked && account && account != undefined">
                    <valid-input
                      v-model="account.accountNumber"
                      vid="accountNumber"
                      name="Account Number"
                      label="Account Number"
                      maxlength="34"
                      type="text"
                      placeholder="Account Number"
                      :disabled="true"
                      :readonly="true"
                      rules="required|max:34|numeric"
                    />
                  </div>
                  <div class="column is-8" v-if="!isLinked && account && account != undefined">
                    <valid-input
                      v-model="account.routingNumber"
                      vid="routingNumber"
                      name="Routing Number"
                      label="Routing Number"
                      maxlength="9"
                      type="text"
                      placeholder="Routing Number"
                      :disabled="true"
                      :readonly="true"
                      rules="required|max:9|length:9|numeric"
                    />
                  </div>
                  <div class="column is-8 card">
                    <b-field label="Account is Closed">
                      {{ `${account.isClosed === true ? 'Yes' : 'No'}` }}
                    </b-field>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>

              <!-- <button
                type="submit"
                class="button is-primary is-rounded"
                :disabled="loading"
                :style="{ float: 'right' }"
              >
                Update Account
              </button> -->
            </form>
          </transition>
        </ValidationObserver>

        <!-- <button
          class="button is-primary is-rounded"
          :disabled="loading"
          :style="{ float: 'left' }"
          @click="eraseAccount"
        >
          Delete Account
        </button> -->
      </div>

      <Activity
        ref="activity"
        :ready="activityReady"
        :isLinked="isLinked"
        :bankAccountID="_get(account, 'bankAccountID', '')"
        :finInstBankName="_get(account, ['financialInstitution', 'name'], '')"
        :finAccountName="_get(account, 'name', '')"
      />

      <UpdateModal ref="updateModal" />
    </div>

    <b-loading :is-full-page="true" v-model="loading" :can-cancel="false" />
  </PageContent>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import PageContent from '@/containers/PageContent'
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
import Activity from './components/Activity'
import UpdateModal from './components/UpdateModal'
import { watch } from './keys/watch'
import { methods } from './keys/methods'
import { data } from './keys/data'
import { computed } from './keys/computed'
import { mounted } from './keys/mounted'

export default {
  components: {
    Activity,
    PageContent,
    UpdateModal,
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider
  },

  computed,
  data,
  methods,
  watch,
  mounted,

  i18n: {
    messages: {
      en: { bankAccount: { detailsTitle: 'Bank Account Details' } }
    }
  }
}
</script>
