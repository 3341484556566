import { formatDate } from '@/filters'
import { formatCurrency } from '@/filters'

import _get from 'lodash/get'

import TxDetails from '../components/TxDetails'

export const watch = {
  hoaId() {
    this.refresh()
  },

  selectedRow(row) {
    this.$router.push({
      name: 'bankaccountlisting',
      params: {
        id: row.bankAccountListingID,
        finInstBankName: this.financialAccountID
      }
    })
  },

  plaidAccountIds(plaidAccountIds) {
    console.log({ plaidAccountIds })
  },

  bankAccountListings(bankAccountListings) {
    const financialInstitution = _get(this, ['account', 'financialInstitution', 'name'], '')
    const accountName = _get(this, ['account', 'name'], '')

    const isLinked = this.isLinked

    this.txsRows = bankAccountListings.map(tx => {
      return {
        date: formatDate(tx.transactionDate),

        payee: _get(tx, ['bankAccount', 'name'], ''),
        description: _get(tx, ['description'], ''),
        amount: formatCurrency(_get(tx, ['amount'], '')),

        checkNumber: _get(tx, ['checkNumber'], ''),

        clearedDate: formatDate(_get(tx, ['clearedDate'], '')),

        ...(!isLinked
          ? {
              details: {
                component: TxDetails,
                props: {
                  id: _get(tx, ['bankAccountListingID'], ''),
                  finInstBankName: `${financialInstitution} - ${accountName}`
                }
              }
            }
          : {})
      }
    })
  }
}
