/*
	import { deleteBankAccount } from '@/services/BankAccounts/BankAccount/Delete';

  const { result, successful, message } = await deleteBankAccount ({
    bankAccountID
  });
  if (!successful) {
    console.error (message);
    return;
  }
*/

import kms from '@/services/kms'

export async function deleteBankAccount({ bankAccountID }) {
  let message = 'The bank account could not be deleted.'

  try {
    const result = await kms.delete(`/BankAccounts/BankAccount/${bankAccountID}?forceDelete=false`)

    return {
      successful: true,
      message: '',
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  return {
    successful: false,
    message,
    result: null
  }
}
