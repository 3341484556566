/*
  example:
    import { getUnlinkedBankAccount } from '@/services/BankAccounts/BankAccountUnlinked/Get';

    const { unlinkedBankAccount, successful, message } = await getUnlinkedBankAccount ({
      bankAccountID
    });
*/

import kms from '@/services/kms'
//import { notifyProblem } from '@/services/notify'

export async function getUnlinkedBankAccount({ bankAccountID } = {}) {
  let message = `This unlinked bank account could not be found.  Possibly, it doesn't exist or was deleted.`

  try {
    const result = await kms.get(`/BankAccounts/BankAccountUnlinked/${bankAccountID}`)

    return {
      unlinkedBankAccount: result,
      //
      successful: true,
      message: '',
      result
    }
  } catch (exception) {
    console.error(exception)
  }

  // notifyProblem (message);

  return {
    unlinkedBankAccount: null,
    //
    successful: false,
    message,
    result: null
  }
}
