export function data() {
  return {
    loading: false,

    region: {
      title: this.$t('title'),
      role: 'main',
      ariaLabel: this.$t('title')
    },
    filters: {
      show: false
    },
    rows: [],
    columns: [
      {
        field: 'name',

        label: 'Name',
        aria: 'Name',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'institution',

        label: 'Institution',
        aria: 'Institution',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'balance',
        prefix: '$',
        numeric: true,

        label: 'Balance',
        aria: 'Balance',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'accountType',

        label: 'Account Type',
        aria: 'Account Type',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'isClosed',
        boolean: true,

        label: 'Is Closed',
        aria: 'Is Closed',
        width: '10%',
        sortable: true,
        searchable: [
          {
            label: 'all',
            filter: ''
          },
          {
            label: 'yes',
            filter: 'yes'
          },
          {
            label: 'no',
            filter: 'no'
          }
        ]
      },
      {
        field: 'processIsLinked',
        boolean: true,

        label: 'Is Linked',
        aria: 'Is Linked',
        width: '10%',
        sortable: true,
        searchable: [
          {
            label: 'all',
            filter: ''
          },
          {
            label: 'yes',
            filter: 'yes'
          },
          {
            label: 'no',
            filter: 'no'
          }
        ]
      },
      {
        field: 'createdDate',
        date: true,
        label: 'Created',
        aria: 'Created',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'menu',
        width: '10%'
      }
      /*{
        field: 'details',
        width: '5%'
      },
      {
        field: 'reconciliation',
        width: '5%'
      }*/
    ]
  }
}
