import { mapState } from 'vuex'

export const computed = {
  ...mapState({
    hoaId: state => state.user.selectedHoaId
  }),
  columns() {
    // const isLinked = this.isLinked

    return [
      {
        field: 'date',
        label: 'Date',
        width: '20%',

        date: true,

        sortable: true,
        searchable: true
      },
      {
        field: 'payee',
        label: 'Payee',
        sortable: true,
        searchable: true
      },
      {
        field: 'description',
        label: 'Description',
        sortable: true,
        searchable: true
      },
      {
        field: 'amount',
        prefix: '$',
        numeric: true,
        label: 'Amount',
        aria: 'Amount',
        sortable: true,
        searchable: true
      },
      {
        field: 'checkNumber',
        label: 'Check Number',
        sortable: true,
        searchable: true
      },
      {
        field: 'clearedDate',
        label: 'Cleared Date',
        sortable: true,
        searchable: true
      }

      /*
      ...(!isLinked
        ? [
            {
              field: 'details',
              label: ''
            }
          ]
        : [])
        */
    ]
  }
}
