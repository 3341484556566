//
import { upsertBankAccountForClient } from '@/services/BankAccounts/BankAccount/UpsertBankAccountForClient'
//
import { notifyMessage, notifyProblem } from '@/services/notify'
import { ValidationError } from '@/services/errors'
//
export async function upsertLinked() {
  const json = this.getLinkedUpsertDetails()
  if (json === false) {
    return
  }

  const { successful, message, exception } = await upsertBankAccountForClient({
    json,
    purpose: 'updated'
  })
  if (!successful) {
    if (exception instanceof ValidationError) {
      this.$refs.form.setErrors(exception.fields)
    }

    notifyProblem(message)
  } else {
    notifyMessage(message)
  }
}
